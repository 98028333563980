"use client";
import { graphql } from "@/gql-generated";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslations } from "next-intl";
import { useRouter } from "next/navigation";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Form, FormInput, FormSubmit } from "@/components/ui/form";
import { useMutation } from "@apollo/client";
import { Text } from "@/components/ui/text";
import * as Sentry from "@sentry/nextjs";

const mutation = graphql(`
	mutation RequestLogin($email: String!, $redirectUrl: String!) {
		requestCodeLogin(input: { email: $email, redirectUrl: $redirectUrl }) {
			clientMutationId
		}
	}
`);

export default function LoginPage({ searchParams }: { searchParams: Record<string, string | string[] | undefined> }) {
	const t = useTranslations("Login");
	const redirectUrl = `${typeof window !== "undefined" ? window.location.origin : ""}/login/code`;
	const router = useRouter();
	const [requestLogin] = useMutation(mutation);

	const schema = z.object({
		email: z.string().email(),
	});

	const form = useForm<z.infer<typeof schema>>({
		resolver: zodResolver(schema),
		defaultValues: {
			email: "",
		},
	});

	const genericError = form.formState.errors.root?.generic;

	const handleSubmit = async (values: z.infer<typeof schema>) => {
		try {
			await requestLogin({
				variables: {
					email: values.email,
					redirectUrl,
				},
			});
			router.push("/login/code?email=" + encodeURIComponent(values.email));
		} catch (error) {
			const sentryId = Sentry.captureException(error);
			form.setError("root.generic", { message: `${t("error-message")} (${sentryId})` });
		}
	};

	return (
		<>
			{searchParams.loggedOut ? (
				<p
					className={
						"rounded-b absolute top-0 h-auto w-full animate-move-out border-b border-red-light bg-red-light/50 p-4 text-center text-sm text-red-light shadow-sm"
					}
				>
					{t("logout-success")}
				</p>
			) : null}
			<Text className="pb-6 text-center">{t("description")}</Text>
			<Form {...form} keepLoadingAfterSuccessfulSubmit>
				<form
					className="flex size-full flex-col gap-3"
					id="login-form"
					onSubmit={form.handleSubmit(handleSubmit)}
				>
					<div className="flex w-full flex-col gap-1">
						<FormInput
							reserveErrorSpace
							name="email"
							autoFocus
							placeholder={t("email-label")}
							type="email"
							autoComplete="username"
						/>
					</div>
					<FormSubmit>{t("request-code")}</FormSubmit>
					{genericError ? (
						<Text className="text-red-light" size={"sm"}>
							{genericError.message}
						</Text>
					) : null}
				</form>
			</Form>
		</>
	);
}
